import React from "react";
import { Link, graphql } from "gatsby";

import { Base } from "../components/Base";
import { Container } from "../components/Container";
import { OmnidocAd } from "../components/OmnidocAd";
import SEO from "../components/seo";

class FaqArticleTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const rootPath = `/info`;

    return (
      <Base>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          isArticle={true}
        />
        <Container>
          <article>
            <Link className="block mt-16 text-action" to={rootPath}>
              ← Toutes les questions
            </Link>

            <div className="flex md:flex-row flex-col">
              <div className="w-full md:w-4/6 md:mr-16">
                <header className="my-8">
                  <h1 className="text-4xl font-bold">
                    {post.frontmatter.title}
                  </h1>
                </header>
                <section
                  className="markdown text-lg"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </div>
              <div className="md:w-2/6">
                <div className="mb-8 ">
                  <OmnidocAd small />
                </div>
                <div className="bg-gray-100 pl-4 rounded py-4 mb-8">
                  <div className="font-semibold text-lg">En savoir plus :</div>
                  {this.props.data.info.edges.map(el => (
                    <div className="my-4">
                      &bull;{" "}
                      <span className="text-action-500">
                        <Link
                          to={el.node.fields.slug}
                          activeClassName="font-bold text-gray-700"
                          className="hover:underline"
                        >
                          {el.node.frontmatter.title}
                        </Link>
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </article>
        </Container>
      </Base>
    );
  }
}

export default FaqArticleTemplate;

export const pageQuery = graphql`
  query FaqArticleBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        description
      }
    }
    info: allMarkdownRemark(
      sort: { fields: [frontmatter___priority], order: ASC }
      filter: { fileAbsolutePath: { regex: "//info/[^/]+/[^/]+.md$/" } }
      limit: 1000
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
